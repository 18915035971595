const config = {
  firebase: {
    apiKey: 'AIzaSyDSeQSckidOnLAPzNLucG3iMwBDsNaLhsI',
    authDomain: 'keta-kiinnostaa.firebaseapp.com',
    databaseURL: 'https://keta-kiinnostaa.firebaseio.com/',
  },
  articlesApi: {
    appId: 'keta_kiinnostaa_prod',
    appKey: '94d2d46908ee86fc7845aef1a8e84360',
    articlesApiUrl: 'https://articles.api.yle.fi/v2/',
  },
  imagesApi: {
    appId: 'keta_kiinnostaa_prod',
    appKey: '94d2d46908ee86fc7845aef1a8e84360',
    imagesApiUrl: 'https://images.api.yle.fi/v1/',
  },
};

export default config;
