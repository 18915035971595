import { put, takeLatest } from 'redux-saga/effects';
import firebase from 'firebase/app';
import 'firebase/database';
import * as actionTypes from '../constants/action-types';

function* getAnswerGroupDataSaga({ answerGroupId }) {
  try {
    const data = yield firebase.database()
      .ref(`/answers/${answerGroupId}`)
      .once('value')
      .then(snapshot => snapshot.val());
    yield put({ type: actionTypes.ANSWER_GROUP_DATA_LOADED, data: data });
  } catch (e) {
    yield put({ type: actionTypes.LOAD_ANSWER_GROUP_DATA_ERROR });
  }
}

export function* watchGetAnswerGroupData() {
  yield takeLatest(actionTypes.GET_ANSWER_GROUP_DATA, getAnswerGroupDataSaga);
}
