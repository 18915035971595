import React from 'react';
import './SwipeButton.css';
import like from './like.png';
import reject from './reject.png'

class SwipeButton extends React.Component {
  render() {
    const { accept, onClick } = this.props;
    return (
      <button onClick={onClick} className="ButtonWrapper">
        {accept ?
          <img src={like} alt="Like" className="ButtonImage" /> :
          <img src={reject} alt="Reject" className="ButtonImage" />
        }
      </button>
    );
  }
}

export default SwipeButton
