import * as actionTypes from '../constants/action-types';

export const addAnswerAction = data => ({
  type: actionTypes.ADD_ANSWER,
  data,
});

export const sendAnswersAction = data => ({
  type: actionTypes.SEND_ANSWERS,
  data,
});

export const setAnswerGroupIdAction = id => ({
  type: actionTypes.SET_ANSWERID,
  id,
});

export const setControlGroupAction = value => ({
  type: actionTypes.SET_CONTROL_GROUP,
  value,
});
