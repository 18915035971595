import * as actionTypes from '../constants/action-types';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_QUIZ: {
      return {
        ...state,
        quiz_loading: true,
        quiz_error: false,
      };
    }
    case actionTypes.QUIZ_LOADED: {
      const { data } = action;
      return {
        ...state,
        articles: data,
        quiz_loading: false,
        quiz_error: false,
      };
    }
    case actionTypes.SET_QUIZ: {
      const { data } = action;
      return {
        ...state,
        articlesWithImages: data,
      };
    }
    case actionTypes.LOAD_QUIZ_ERROR: {
      return {
        ...state,
        quiz_loading: false,
        quiz_error: true,
      };
    }
    case actionTypes.ADD_ANSWER: {
      const { data } = action;
      const { articleId, answer } = data;
      return {
        ...state,
        answers: {...state.answers, [articleId]: answer},
      };
    }
    case actionTypes.SET_ANSWERID: {
      const { id } = action;
      return {
        ...state,
        answerId: id,
      };
    }
    case actionTypes.SET_CONTROL_GROUP: {
      const { value } = action;
      return {
        ...state,
        controlGroup: value,
      };
    }
    default:
      return state;
  }
}
