import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import Admin from './screens/Admin/Admin'
import Swipe from './screens/Swipe/Swipe'
import Landing from './screens/Landing/Landing'
import Results from './screens/Results/Results'
import { initializeAction } from './actions/initialize-action';

class Main extends React.PureComponent {
  async componentDidMount() {
    const { dispatch } = this.props;
    firebase.auth().signInAnonymously();
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch(initializeAction());
      }
    })
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/swipe" component={Swipe} />
          <Route path="/results" component={Results} />
          <Route path="/control" component={(props) => (<Landing controlGroup={true} {...props}/>)} />
          <Route path="/admin/:answerGroup" component={Admin} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(Main)
