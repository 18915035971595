import fetch from 'node-fetch';
import config from '../config';

const getArticles = async (articleIds) => {
  const url = `${config.articlesApi.articlesApiUrl}articles.json?app_id=${config.articlesApi.appId}&app_key=${config.articlesApi.appKey}&id=${articleIds}`;
  const data = await fetch(url);
  const json = await data.json();
  if (json.data) {
    return json.data;
  } return null;
};

export { getArticles };
