import * as actionTypes from '../constants/action-types';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ANSWER_GROUP_DATA: {
      return {
        ...state,
        answerDataLoading: true,
        answerDataError: false,
      };
    }
    case actionTypes.ANSWER_GROUP_DATA_LOADED: {
      const { data } = action;
      return {
        ...state,
        answers: data,
        answerDataLoading: false,
        answerDataError: false,
      };
    }
    case actionTypes.LOAD_ANSWER_GROUP_DATA_ERROR: {
      return {
        ...state,
        answerDataLoading: false,
        answerDataError: true,
      };
    }
    default:
      return state;
  }
}
