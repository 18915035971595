import React from 'react';
import { connect } from 'react-redux';
import { sendAnswersAction } from '../../actions/answer-action'
import './ControlEnding.css';

class ControlEnding extends React.Component {
  componentDidMount() {
    const { answerId, answers} = this.props.quiz;
    const data = { answerId: answerId, answers: answers }
    this.props.dispatch(sendAnswersAction(data));
  }

  render() {
    return (
      <div className="controlResultText">Kiitos vastauksistasi!</div>
    );
  }
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(ControlEnding);
