import React from 'react';
import { connect } from 'react-redux';
import './Landing.css';
import logo from './logo.png';
import { setAnswerGroupIdAction, setControlGroupAction } from '../../actions/answer-action'

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answerId: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({answerId: event.target.value.toUpperCase()});
  }

  handleSubmit(event) {
    if (this.props.controlGroup === true) {
      this.props.dispatch(setControlGroupAction(true));
    } else {
      this.props.dispatch(setControlGroupAction(false));
    }
    this.props.dispatch(setAnswerGroupIdAction(this.state.answerId));
    this.props.history.push("/swipe");
  }

  render() {
    return (
      <div className="Landing">
        <img src={logo} alt="Ketä kiinnostaa -logo" className="Logo" />
        <div className="LogoText">Testaa kuinka hyvin tunnet yleisösi!</div>
        <input type="text" value={this.state.answerId} onChange={this.handleChange} className="AnswerIdInput" placeholder="Kirjoita koodi tähän"/>
        <input type="button" onClick={this.handleSubmit} className="SubmitButton" value="Pelaamaan!" disabled={this.state.answerId.length < 3}/>
      </div>
    );
  }
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(Landing);
