import React from 'react';
import './Subject.css';

class Subject extends React.Component {
  render() {
    const { subject } = this.props;
    return (
      <div className="Subject">
        {subject.toUpperCase()}
      </div>
    );
  }
}

export default Subject
