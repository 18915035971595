import React from 'react';
import { connect } from 'react-redux';
import AdminCard from "../../components/AdminCard/AdminCard"
import { setQuizAction } from '../../actions/quiz-action'
import { getImage } from '../../utils/imagesApi';
import './Admin.css';
import { getAnswerGroupDataAction } from '../../actions/admin-action'

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: this.props.quiz.articles
    };
  }
  componentWillMount() {
    const { match: { params } } = this.props;
    this.props.dispatch(getAnswerGroupDataAction(params.answerGroup));
  }
  componentDidMount() {
    const { articles } = this.props.quiz;
    Promise.all(articles.map(async article => {
      if (article.headline.image) {
        const image = await getImage(article.headline.image.id);
        return {...article, imageUrl: image.secure_url}
      } else {
        const image = await getImage(article.mainMedia[0].image.id);
        return {...article, imageUrl: image.secure_url}
      }
    }))
      .then(articlesWithImages => {
        this.setState({articles: articlesWithImages})
        this.props.dispatch(setQuizAction(articlesWithImages))
      })
  }
  render() {
    const { match: { params }} = this.props;
    const { articles } = this.state;
    const { answerDataError, answerDataLoading, answers } = this.props.admin;
    let correctAnswers = 0;
    let totalAnswers = 0;
    let answersArray = [];
    let correctAnswersByArticle = [];
    if (answers && articles.length > 0) {
      answersArray = Object.values(answers);
      for (let i = 0; i < answersArray.length; i++) {
        totalAnswers = totalAnswers + Object.keys(answersArray[i]).length;
      }
      for (let i = 0; i < articles.length; i++) {
        const articleAnswers = answersArray.map(answer => answer[articles[i].id]);
        const correctArticleAnswers = articleAnswers.filter(answer => answer === articles[i].answer);
        correctAnswers = correctAnswers + correctArticleAnswers.length;
        correctAnswersByArticle = [...correctAnswersByArticle, {id: articles[i].id, title: articles[i].headline.full, lead: articles[i].lead, imageUrl: articles[i].imageUrl, subjects: articles[i].subjects, correctAnswers: correctArticleAnswers.length}]
      }
    }
    return (
      <div className="Admin">
        {answerDataError ?
          <div className="AdminInfoText">Virhe ladatessa tietoja</div> :
          answerDataLoading ?
          <div className="AdminInfoText">Ladataan tietoja</div> :
          !answers || answers.length === 0 ?
          <div className="AdminInfoText">Ei vastauksia koodilla {params.answerGroup}</div> :
          <div>
            <div className="AnswerGroupTitle">Vastaajaryhmä {params.answerGroup}</div>
            <div className="AdminInfoText">Vastaajia yhteensä {answersArray.length}</div>
            <div className="AdminInfoText">Vastauksia yhteensä {totalAnswers}</div>
            <div className="AdminInfoText">Oikeita vastauksia yhteensä {correctAnswers} ({(correctAnswers/totalAnswers * 100).toFixed(2)}%)</div>
            <div className="AdminInfoText">Keskimäärin oikein {(correctAnswers/answersArray.length).toFixed(1)}/{articles.length} per käyttäjä</div>
            <div className="ResultsByArticle">Kysymyksittäin:</div>
            {correctAnswersByArticle.map(article => {
              return (
                <div className="ArticleResults">
                  <AdminCard article={article} />
                  <div className="AdminInfoText">Oikeita vastauksia {article.correctAnswers} ({(article.correctAnswers/answersArray.length * 100).toFixed(2)}%)</div>
                </div>
              )
            })}
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(Admin);
