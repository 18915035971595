import { all, fork } from 'redux-saga/effects';
import { watchGetAnswerGroupData } from './admin-saga';
import { watchInitialize } from './initialize-saga';
import { watchLoadQuiz, watchSendAnswers } from './quiz-saga';

export default function* () {
  yield all([
    fork(watchGetAnswerGroupData),
    fork(watchInitialize),
    fork(watchLoadQuiz),
    fork(watchSendAnswers),
  ]);
}
