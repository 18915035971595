import { takeLatest, put } from 'redux-saga/effects';
import firebase from 'firebase/app';
import 'firebase/database';
import * as actionTypes from '../constants/action-types';
import { getArticles } from '../utils/articlesApi';

function* loadQuizSaga() {
  try {
    const data = yield firebase.database()
      .ref('/quiz/')
      .once('value')
      .then(snapshot => snapshot.val());
    const articleData = yield getArticles(Object.keys(data).toString());
    const combinedData = articleData.map(article => {
      const answer = data[article.id]
      return {...article, answer}
    })
    yield put({ type: actionTypes.QUIZ_LOADED, data: combinedData });
  } catch (e) {
    yield put({ type: actionTypes.LOAD_QUIZ_ERROR });
  }
}

export function* watchLoadQuiz() {
  yield takeLatest(actionTypes.LOAD_QUIZ, loadQuizSaga);
}

function* sendAnswersSaga({ data }) {
  const { answerId, answers } = data;
  const ref = `answers/${answerId}/`;
  yield firebase.database()
    .ref(ref)
    .push(answers);
}

export function* watchSendAnswers() {
  yield takeLatest(actionTypes.SEND_ANSWERS, sendAnswersSaga);
}
