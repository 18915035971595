import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../constants/action-types';

function* initializeSaga() {
  yield put({ type: actionTypes.LOAD_QUIZ });
}

export function* watchInitialize() {
  yield takeLatest(actionTypes.INITIALIZE, initializeSaga);
}
