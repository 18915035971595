export const ADD_ANSWER = 'ADD_ANSWER';
export const SEND_ANSWERS = 'SEND_ANSWERS';
export const SET_ANSWERID = 'SET_ANSWERID';

export const INITIALIZE = 'INITIALIZE';

export const LOAD_QUIZ = 'LOAD_QUIZ';
export const LOAD_QUIZ_ERROR = 'LOAD_QUIZ_ERROR';
export const QUIZ_LOADED = 'QUIZ_LOADED';
export const SET_QUIZ = 'SET_QUIZ';

export const GET_ANSWER_GROUP_DATA = 'GET_ANSWER_GROUP_DATA';
export const ANSWER_GROUP_DATA_LOADED = 'ANSWER_GROUP_DATA_LOADED';
export const LOAD_ANSWER_GROUP_DATA_ERROR = 'LOAD_ANSWER_GROUP_DATA_ERROR';

export const SET_CONTROL_GROUP = 'SET_CONTROL_GROUP';
