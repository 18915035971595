import fetch from 'node-fetch';
import config from '../config';

const getImage = async (imageId) => {
  const url = `${config.imagesApi.imagesApiUrl}images/${imageId}.json?app_id=${config.imagesApi.appId}&app_key=${config.imagesApi.appKey}`;
  const data = await fetch(url);
  const json = await data.json();
  return json;
};

export { getImage };
