import React from 'react';
import './ResultCard.css';
import thumbsUp from './thumbs-up.png';
import thumbsDown from './thumbs-down.png';
import like from './like.png';
import reject from './reject.png';

class ResultCard extends React.Component {
  render() {
    const { article, correct, answer } = this.props;
    return (
      <div className="ResultCard">
        <img className="ResultImage" src={article.imageUrl} alt="Article headline img" />
        {correct ?
          <img className="Thumbs" src={thumbsUp} alt="Thumbs up" /> :
          <img className="Thumbs" src={thumbsDown} alt="Thumbs down" />
        }
        <div className="ResultContentWrapper">
          <div className="ResultTitle">{article.headline.full}</div>
          <div className="ResultInfo">
            <div className="ResultCategoryWrapper">
              <div className="ResultInfoText">Sinä:</div>
              {answer ?
                <img className="AnswerImg" src={like} alt="Like" /> :
                <img className="AnswerImg" src={reject} alt="Reject" />
              }
            </div>
            <div className="ResultCategoryWrapper">
              <div className="ResultInfoText">Alle 30-vuotiaat:</div>
              {article.answer ?
                <img className="AnswerImg" src={like} alt="Like" /> :
                <img className="AnswerImg" src={reject} alt="Reject" />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResultCard
