import React from 'react';
import './AdminCard.css';
import Subject from '../Subject/Subject'

class AdminCard extends React.Component {
  render() {
    const { article } = this.props;
    return (
      <div className="AdminCard">
        <img className="articleImage" src={article.imageUrl ? article.imageUrl : 'https://images.cdn.yle.fi/image/upload/ar_1.91,c_crop/17-6372357f4a79a13ed3.jpg'} alt="Article headline img" />
        <Subject subject={article.subjects[0].title.fi} />
        <div className="articleTitle">{article.title}</div>
      </div>
    );
  }
}

export default AdminCard
