import React from 'react';
import { Provider } from 'react-redux';
import firebase from 'firebase/app';
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './store/configure-store';
import Main from './Main';
import config from './config';

const { store, persistor } = configureStore();
firebase.initializeApp(config.firebase);

const KetaKiinnostaa = () => (
  <div className="PreventOverflow">
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Main />
      </PersistGate>
    </Provider>
  </div>
);

export default KetaKiinnostaa;
