import React from 'react';
import { connect } from 'react-redux';
import Swipeable from "react-swipy"
import { Redirect } from 'react-router-dom'
import { addAnswerAction } from '../../actions/answer-action'
import { setQuizAction } from '../../actions/quiz-action'
import Card from "../../components/Card/Card"
import ControlEnding from "../../components/ControlEnding/ControlEnding"
import SwipeButton from "../../components/SwipeButton/SwipeButton"
import { getImage } from '../../utils/imagesApi';
import './Swipe.css';

class Swipe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: this.props.quiz.articles
    };
    this.handleSwipe = this.handleSwipe.bind(this);
  }
  remove = () => this.setState(({ articles }) => ({ articles: articles.slice(1, articles.length) }));
  handleSwipe(direction) {
    const articleId = this.state.articles[0].id;
    if (direction === "right") {
      const data = { articleId: articleId, answer: true }
      this.props.dispatch(addAnswerAction(data));
    } else {
      const data = { articleId: articleId, answer: false }
      this.props.dispatch(addAnswerAction(data));
    }
  }
  componentDidMount() {
    const { articles } = this.props.quiz;
    Promise.all(articles.map(async article => {
      if (article.headline.image) {
        const image = await getImage(article.headline.image.id);
        return {...article, imageUrl: image.secure_url}
      } else {
        const image = await getImage(article.mainMedia[0].image.id);
        return {...article, imageUrl: image.secure_url}
      }
    }))
      .then(articlesWithImages => {
        this.setState({articles: articlesWithImages})
        this.props.dispatch(setQuizAction(articlesWithImages))
      })
    window.scrollTo(0, 0)
  }
  render() {
    const { articles } = this.state;
    const originalArticleCount = this.props.quiz.articles.length;
    const { controlGroup } = this.props.quiz;
    const wrapperStyles = {position: "relative", height: "410px"};
    const actionsStyles = {
      display: "flex",
      justifyContent: "center",
      marginLeft: 30,
      marginRight: 30,
      marginBottom: 30,
    };
    const progressWidth = ((originalArticleCount - articles.length) / originalArticleCount) * 100;
    return (
      <div className="Swipe">
        <div className="progress" style={{width:`${progressWidth}%`}}/>
        <div className="title">Avaisiko alle 30-vuotias tämän? <span role="img" aria-label="Emoji point down">👇</span></div>
        <div style={wrapperStyles}>
          {articles.length > 0 ? (
            <div style={wrapperStyles}>
              <Swipeable
                onAfterSwipe={this.remove}
                onSwipe={this.handleSwipe}
                buttons={({ right, left }) => (
                  <div style={actionsStyles}>
                    <SwipeButton onClick={left} />
                    <SwipeButton onClick={right} accept />
                  </div>
                )}
              >
                <Card article={articles[0]} />
              </Swipeable>
              {articles.length > 1 && <Card zIndex={-1} article={articles[1]} />}
            </div>
          ) : (
            controlGroup === true ?
              <ControlEnding /> :
              <Redirect to='/results' />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(Swipe);
