import React from 'react';
import { connect } from 'react-redux';
import { sendAnswersAction } from '../../actions/answer-action'
import './Results.css';
import confetti from './confetti.png';
import ResultCard from '../../components/ResultCard/ResultCard'

class Results extends React.Component {
  componentDidMount() {
    const { answerId, answers} = this.props.quiz;
    const data = { answerId: answerId, answers: answers }
    this.props.dispatch(sendAnswersAction(data));
  }

  render() {
    const articles = this.props.quiz.articlesWithImages;
    let correctAnswers = 0;
    for (let i = 0; i < articles.length; i++) {
      if (articles[i].answer === this.props.quiz.answers[articles[i].id]) {
        correctAnswers = correctAnswers + 1;
      }
    }

    return (
      <div className="Results">
        <img src={confetti} alt="Confetti" className="Confetti" />
        <div className="ScoreNumber">{correctAnswers}/{articles.length}</div>
        <div className="CopyText">VASTAUKSISTA OIKEIN!</div>
        <div className="CopyTextSmall">NÄIN ARVAUKSESI OSUIVAT</div>
        {articles.map(article => {
          let correct = false;
          if (article.answer === this.props.quiz.answers[article.id]) {
            correct = true;
          }
          return <ResultCard article={article} correct={correct} answer={this.props.quiz.answers[article.id]} key={article.id} />
        })}
      </div>
    );
  }
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(Results);
