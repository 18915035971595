import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from '../reducers';
import rootSaga from '../sagas';

export default function configureStore() {
  const persistConfig = {
    key: 'root',
    storage,
  }
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  const sagaMiddleware = createSagaMiddleware();
  const enhancer = compose(
    applyMiddleware(
      sagaMiddleware,
    ),
  );
  const preloadedState = {
    quiz: {
      answerId: '',
      answers: {},
      articles: [],
      controlGroup: false,
    }
  }
  const store = {
    ...createStore(persistedReducer, preloadedState, enhancer),
    runSaga: sagaMiddleware.run(rootSaga),
  };
  const persistor = persistStore(store)
  return {store, persistor};
}
